import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../../components/sale/partner';
import ConfDialog from '../../../components/ConfDialog';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'sale_dt', label: '売上日', alignRight: false },
  { id: 'product_code', label: '商品', alignRight: false },
  { id: 'lot_number', label: 'ロット番号', alignRight: false },
  { id: 'device_code', label: 'デバイス', alignRight: false },
  { id: 'price', label: '売上額', alignRight: true },
  { id: 'commission', label: '手数料率', alignRight: true },
  { id: 'profit', label: '収益額', alignRight: true }
  // { id: 'profit', label: '状況', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, status, partnerCode, dt_s, dt_e) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.with_product &&
        (_target.with_product.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
          _target.with_product.code.toLowerCase().indexOf(query.toLowerCase()) !== -1)
    );
  }
  if (status !== -1) {
    array = filter(array, (_target) => _target.status === status);
  }
  if (partnerCode !== -1) {
    array = filter(array, (_target) => _target.partner_code === partnerCode);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Sale() {
  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => {});

  // フィルタ用
  const [status, setStatus] = useState(-1);
  const [partnerCode, setPartnerCode] = useState(-1);
  const [dt_s, setdtS] = useState(moment().format('YYYY-MM-01'));
  const [dt_e, setdtE] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  const [sum_profit, setSumProfit] = useState(0);
  const [sum_price, setSumPrice] = useState(0);

  useEffect(() => {
    // getList();
  }, []);

  useEffect(() => {
    getList();
  }, [dt_s, dt_e]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName,
    status,
    partnerCode,
    dt_s,
    dt_e
  );

  useEffect(() => {
    if (filteredItems) {
      let sum_pt = 0;
      let sum_pr = 0;
      filteredItems.forEach((v) => {
        sum_pt += v.profit;
        sum_pr += v.price;
      });
      setSumProfit(sum_pt);
      setSumPrice(sum_pr);
    }
  }, [filteredItems]);

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api(
      'sale/partner/list',
      {
        dt_s,
        dt_e
      },
      'post'
    );
    if (data.result) {
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (id) => {
    const { data } = await SystemLib.api(
      `sale/item`,
      {
        id
      },
      'post'
    );
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.id === id) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  // const deleteItems = async (ids) => {
  //   const tmp = chunk(ids, 1000);
  //   const p = [];
  //   tmp.forEach(async (arr) => {
  //     console.log(arr);
  //     p.push(
  //       SystemLib.api(
  //         `stock/delete`,
  //         {
  //           ids: arr
  //         },
  //         'post'
  //       )
  //     );
  //   });
  //   await Promise.all(p);
  //   const newItems = list.filter((v) => !ids.includes(v.id));
  //   setList(newItems);
  // };

  const reload = async (id) => {
    getItem(id);
  };

  const classes = useStyles();

  const numberWithComma = new Intl.NumberFormat();
  const profitSumFormat = numberWithComma.format(sum_profit);
  const priceSumFormat = numberWithComma.format(sum_price);

  return (
    <>
      <Page title="売上一覧">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={5} mb={1}>
            <Typography variant="h4">売上一覧</Typography>
            <Typography variant="h6" color="#2e8b57">
              期間内 収益合計：{profitSumFormat}円
            </Typography>
            <Typography variant="h6">期間内 売上合計：{priceSumFormat}円</Typography>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {
                setConfAll(true);
              }}
              onChangeStatus={(status) => {
                setStatus(status);
              }}
              onChangePartnerCode={(code) => {
                setPartnerCode(code);
              }}
              onChangeDateS={(dt) => {
                setdtS(dt);
              }}
              onChangeDateE={(dt) => {
                setdtE(dt);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id,
                          sale_code,
                          sale_dt,
                          partner_code,
                          product_code,
                          lot_number,
                          device_code,
                          column_number,
                          price,
                          profit,
                          status,
                          approved_dt,
                          created_at,
                          updated_at,
                          deleted_at,
                          with_partner,
                          with_product,
                          with_device,
                          commission
                        } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        const numberWithComma = new Intl.NumberFormat();
                        const priceFormat = numberWithComma.format(price);
                        const profitFormat = numberWithComma.format(profit);
                        let statusLabel = '';
                        if (status === 'sale') {
                          statusLabel = ['未承認'];
                        } else {
                          statusLabel = ['承認済', <br />, approved_dt];
                        }
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            {/* <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id)}
                              />
                            </TableCell> */}
                            <TableCell scope="row" sx={{ paddingLeft: 3 }}>
                              {moment(sale_dt).format('YYYY-MM-DD')}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {[with_product.code, <br />, with_product.name]}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {lot_number}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {[with_device.code, <br />, with_device.name]}
                            </TableCell>
                            <TableCell scope="row" padding="normal" align="right">
                              {priceFormat}
                            </TableCell>
                            <TableCell scope="row" padding="normal" align="right">
                              {commission}%
                            </TableCell>
                            <TableCell scope="row" padding="normal" align="right">
                              {profitFormat}
                            </TableCell>
                            {/* <TableCell scope="row" padding="normal">
                              {statusLabel}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={7} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        <ConfDialog
          open={conf}
          onOk={() => {
            confOk();
            setConf(false);
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            confOk();
            setSelected([]);
            setConfAll(false);
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
