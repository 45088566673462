import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function LayinModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`layin/item`, { id }, 'post');
    if (result) {
      formik.setValues({
        // class: data.xxx,
        order_dt: moment(data.order_dt).toDate(),
        partner: `${data.partner_code}  ${data.with_partner.name}`,
        product: `${data.product_code}  ${data.with_product.name}`,
        lot_number: data.lot_number,
        order_quantity: data.order_quantity,
        ship_dt: moment(data.ship_dt).toDate(),
        stock_expected_dt: moment(data.stock_expected_dt).toDate(),
        stock_dt: moment(data.stock_dt).toDate(),
        stock_quantity: data.stock_quantity,
        expiry_dt: moment(data.expiry_dt).toDate(),
        status: data.status
      });
    }
  };

  const FormSchema = Yup.object().shape({
    expiry_dt: Yup.date().required('消費期限日は必須です')
  });

  const formik = useFormik({
    initialValues: {
      order_dt: '',
      partner: '',
      product: '',
      lot_number: '',
      order_quantity: 0,
      ship_dt: '',
      stock_expected_dt: '',
      stock_dt: '',
      stock_quantity: 0,
      expiry_dt: '',
      status: ''
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `layin/edit`,
        {
          id: targetId,
          expiry_dt: moment(formik.values.expiry_dt).format('YYYY-MM-DD')
        },
        'post'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Draggable
              cancel="input"
              defaultPosition={{ x: 0, y: 0 }}
              positionOffset={{ x: '-50%', y: '-50%' }}
            >
              <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
                {/* <Stack direction="row" width="100%" mb={2}> */}
                <Stack direction="column" spacing={2} mt={1} mr={2} width="100%">
                  <TextField
                    id="partner"
                    label="パートナー"
                    variant="outlined"
                    value={formik.values.partner}
                    disabled
                  />
                  <TextField
                    id="product"
                    label="商品"
                    variant="outlined"
                    value={formik.values.product}
                    disabled
                  />
                  <TextField
                    id="lot_number"
                    label="ロット番号"
                    variant="outlined"
                    value={formik.values.lot_number}
                    disabled
                  />
                  <DesktopDatePicker
                    id="expiry_dt"
                    label="消費期限日"
                    inputFormat="yyyy-MM-dd"
                    {...getFieldProps('expiry_dt')}
                    value={formik.values.expiry_dt}
                    onChange={(v) => {
                      formik.setFieldValue('expiry_dt', moment(v).toDate());
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={errors.expiry_dt ? '消費期限日をご確認ください' : null}
                      />
                    )}
                  />
                </Stack>
                {/* </Stack> */}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                  sx={{ marginTop: 3 }}
                >
                  {`${targetId === 0 ? '登録' : '更新'}する`}
                </LoadingButton>
              </Box>
            </Draggable>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
