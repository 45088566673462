import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function CategoryModal({
  targetId = 0,
  parentId = 0,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`category/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        code: data.code,
        name: data.name
      });
    }
  };

  Yup.addMethod(Yup.string, 'unique', function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique', '既に使用されています', async (value) => {
      if (value == null || value === '') return true;
      try {
        const {
          data: { result }
        } = await SystemLib.api(
          `category/check`,
          {
            id: targetId,
            code: value
          },
          'post'
        );
        return result;
      } catch (e) {
        return true;
      }
    });
  });

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const FormSchema = Yup.object().shape({
    code: Yup.string().required('カテゴリーコードは必須です').unique(),
    name: Yup.string().required('カテゴリー名は必須です')
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      name: ''
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `category${targetId === 0 ? '' : `/${targetId}`}`,
        {
          code: formik.values.code,
          name: formik.values.name,
          parent_id: parentId
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Draggable
              cancel="input"
              defaultPosition={{ x: 0, y: 0 }}
              positionOffset={{ x: '-50%', y: '-50%' }}
            >
              <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
                <Stack direction="column" spacing={2} mb={2}>
                  <TextField
                    id="code"
                    label="カテゴリーコード"
                    variant="outlined"
                    {...getFieldProps('code')}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                  />
                  <TextField
                    id="name"
                    label="カテゴリー名"
                    variant="outlined"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </Stack>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  {`${targetId === 0 ? '登録' : '更新'}する`}
                </LoadingButton>
              </Box>
            </Draggable>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
