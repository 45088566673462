import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  Checkbox
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import DetailListHead from '../../components/approve/DetailListHead';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function DetailModal({ ym, partner_code, open, onClose, updateDt }) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (ym && partner_code) {
      _getList(ym, partner_code);
    }
  }, [ym, partner_code, updateDt]);

  const _getList = async (ym, partner_code) => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `approve/detail`,
      {
        ym,
        partner_code
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      setList(data);
    }
  };

  const TABLE_HEAD = [
    { id: 'product_code', label: '商品', alignRight: false },
    { id: 'price', label: '価格', alignRight: true },
    { id: 'quantity', label: '個数', alignRight: true },
    { id: 'sales_amount', label: '売上額', alignRight: true },
    { id: 'profit_amount', label: '収益額', alignRight: true },
    { id: 'fee_amount', label: '管理費', alignRight: true }
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="table,button"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Scrollbar>
                <Stack direction="row-reverse">
                  <Button
                    size="medium"
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      navigate(`/sale/list/${partner_code}/${ym}`);
                    }}
                  >
                    該当月の売上一覧へ移動
                  </Button>
                </Stack>
                <TableContainer sx={{ minWidth: 500, marginTop: 2 }}>
                  <Table>
                    <DetailListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                    <TableBody>
                      {list.map((row, index) => (
                        <ItemRow key={index} row={row} onChange={(v) => {}} onValid={(v) => {}} />
                      ))}
                      {isLoading === false && list.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                対象在荷がありません
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                読み込み中
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
    </>
  );
}

function ItemRow({ row }) {
  const { product_code, sales_amount, profit_amount, fee_amount, quantity, with_product } = row;
  const numberWithComma = new Intl.NumberFormat();
  const salesSumFormat = numberWithComma.format(sales_amount);
  const profitSumFormat = numberWithComma.format(profit_amount);
  const feeSumFormat = numberWithComma.format(fee_amount);
  const priceFormat = numberWithComma.format(with_product.price);

  return (
    <TableRow hover key={product_code} tabIndex={-1}>
      <TableCell scope="row>">{[product_code, <br />, with_product.name]}</TableCell>
      <TableCell scope="row>" align="right">
        {priceFormat}
      </TableCell>
      <TableCell scope="row" align="right">
        {quantity}
      </TableCell>
      <TableCell scope="row" align="right">
        {salesSumFormat}
      </TableCell>
      <TableCell scope="row" align="right">
        {profitSumFormat}
      </TableCell>
      <TableCell scope="row" align="right">
        {feeSumFormat}
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
