import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter, groupBy } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem,
  Paper,
  InputAdornment,
  Checkbox
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import jaLocale from 'date-fns/locale/ja';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function PartnerProductModal({
  targetGroup = 0,
  targetId = 0,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  const [categories1, setCategories1] = useState([]);
  const [categories2, setCategories2] = useState([]);
  const [categories3, setCategories3] = useState([]);

  const [commissions, setCommissions] = useState([]);

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetGroup, targetId, updateDt]);

  const _init = async () => {
    const { data } = await SystemLib.api('category/list', {}, 'post');
    if (data.result) {
      setCategories1(data.data ? data.data : []);
    }
    const { data: dataC } = await SystemLib.api('commission', {}, 'get');
    if (dataC.result) {
      setCommissions(dataC.data ? dataC.data : []);
    }
  };

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`partner/product/${id}`, {}, 'get');
    if (result) {
      const ret1 = await SystemLib.api('category/list', { code: data.category_code1 }, 'post');
      if (ret1.data.result) {
        setCategories2(ret1.data.data ? ret1.data.data : []);
      }
      if (data.category_code2) {
        const ret2 = await SystemLib.api('category/list', { code: data.category_code2 }, 'post');
        if (ret2.data.result) {
          setCategories3(ret2.data.data ? ret2.data.data : []);
        }
      }

      console.log(data);

      formik.setValues({
        partner_code: data.partner_code,
        code: data.code,
        name: data.name,
        price: data.price,
        throughput: data.throughput,
        expiring: data.expiring,
        // size: data.size,
        // caution_count: data.caution_count,
        // warning_count: data.warning_count,
        category_code1: data.category_code1 ? data.category_code1 : '-1',
        category_code2: data.category_code2 ? data.category_code2 : '-1',
        category_code3: data.category_code3 ? data.category_code3 : '-1',
        lot_order: data.lot_order,
        commission_code: data.commission_code,
        commission_current: data.commission_current,
        commission_next: data.commission_next,
        commission_dt: data.commission_dt ? moment(data.commission_dt).toDate() : null,
        code_csv: data.code_csv ? data.code_csv : '',
        update_sales: '0'
      });
    }
  };

  Yup.addMethod(Yup.string, 'unique', function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique', '既に使用されています', async (value) => {
      if (value == null || value === '') return true;
      try {
        const {
          data: { result }
        } = await SystemLib.api(
          `partner/product/check`,
          {
            id: targetId,
            code: value
          },
          'post'
        );
        return result;
      } catch (e) {
        return true;
      }
    });
  });

  Yup.addMethod(Yup.string, 'unique_csvcode', function () {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test('unique_csvcode', '既に使用されています', async (value) => {
      if (value == null || value === '') return true;
      try {
        const {
          data: { result }
        } = await SystemLib.api(
          `partner/product/check_csvcode`,
          {
            id: targetId,
            code_csv: value
          },
          'post'
        );
        return result;
      } catch (e) {
        return true;
      }
    });
  });

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));
  const yupNumF = Yup.number()
    .typeError('数字を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable();

  const FormSchema = Yup.object().shape({
    // code: Yup.string().required('商品コードは必須です').unique(),
    name: Yup.string().required('商品名は必須です'),
    price: yupNum.required('価格は必須です'),
    throughput: yupNum.required('製造日数は必須です'),
    expiring: yupNum.required('消費期限は必須です'),
    // size: Yup.number()
    //   .typeError('数字を入力してください')
    //   .integer('整数を入力してください')
    //   .min(1, '1以上の数字を入れてください')
    //   .nullable()
    //   .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    //   .required('商品サイズは必須です'),
    // caution_count: yupNum.required('補充検討個数は必須です'),
    // warning_count: yupNum.required('要補充個数は必須です'),
    category_code1: Yup.string().matches(/^(?!-).*$/, 'カテゴリー1は必須です'),
    lot_order: yupNum.required('発注ロットは必須です'),
    commission_code: Yup.string().matches(/^(?!-).*$/, '契約は必須です'),
    commission_dt: Yup.date().nullable().default(null),
    commission_current: yupNumF.required('手数料率は必須です'),
    commission_next: yupNumF.required('手数料率(適用日以降)は必須です'),
    code_csv: Yup.string().unique_csvcode()
  });

  const formik = useFormik({
    initialValues: {
      partner_code: targetGroup,
      code: '',
      name: '',
      price: '',
      throughput: '',
      expiring: '',
      // size: '',
      // caution_count: '',
      // warning_count: '',
      category_code1: '-1',
      category_code2: '-1',
      category_code3: '-1',
      lot_order: '',
      commission_code: '-1',
      commission_dt: null,
      commission_current: 0,
      commission_next: 0,
      code_csv: '',
      update_sales: '0'
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `partner/product${targetId === 0 ? '' : `/${targetId}`}`,
        {
          partner_code: targetId === 0 ? targetGroup : formik.values.partner_code,
          code: formik.values.code,
          name: formik.values.name,
          price: formik.values.price,
          throughput: formik.values.throughput,
          expiring: formik.values.expiring,
          // size: formik.values.size,
          // caution_count: formik.values.caution_count,
          // warning_count: formik.values.warning_count,
          category_code1: formik.values.category_code1,
          category_code2:
            formik.values.category_code2 !== '-1' ? formik.values.category_code2 : null,
          category_code3:
            formik.values.category_code3 !== '-1' ? formik.values.category_code3 : null,
          lot_order: formik.values.lot_order,
          commission_code: formik.values.commission_code,
          commission_dt: formik.values.commission_dt
            ? moment(formik.values.commission_dt).format('YYYY-MM-DD')
            : null,
          commission_current: formik.values.commission_current,
          commission_next: formik.values.commission_next,
          code_csv: formik.values.code_csv,
          update_sales: formik.values.update_sales
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        console.log(data);
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Draggable
              cancel="input"
              defaultPosition={{ x: 0, y: 0 }}
              positionOffset={{ x: '-50%', y: '-50%' }}
            >
              <Box sx={styles.box} spacing={2} overflow="scroll">
                <Scrollbar>
                  <Stack direction="column" spacing={2} mt={1}>
                    {targetId !== 0 && (
                      <TextField
                        id="code"
                        label="商品コード"
                        variant="outlined"
                        value={formik.values.code}
                        disabled
                      />
                    )}
                    <FormControl error={Boolean(touched.category_code1 && errors.category_code1)}>
                      <Select
                        labelId="category_code1"
                        id="category_code1"
                        value={formik.values.category_code1}
                        {...getFieldProps('category_code1')}
                        onChange={async (e) => {
                          formik.handleChange(e);
                          formik.setFieldValue('category_code2', '-1');
                          formik.setFieldValue('category_code3', '-1');
                          const { data } = await SystemLib.api(
                            'category/list',
                            { code: e.target.value },
                            'post'
                          );
                          if (data.result) {
                            setCategories2(data.data ? data.data : []);
                          }
                        }}
                        error={Boolean(touched.category_code1 && errors.category_code1)}
                      >
                        <MenuItem value="-1">カテゴリー1選択...</MenuItem>
                        {categories1.map((v) => (
                          <MenuItem key={v.code} value={v.code}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.category_code1 && errors.category_code1 && (
                        <FormHelperText sx={{ color: '#f00' }}>
                          カテゴリー1は必須です
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl>
                      <Select
                        labelId="category_code2"
                        id="category_code2"
                        value={formik.values.category_code2}
                        {...getFieldProps('category_code2')}
                        onChange={async (e) => {
                          formik.handleChange(e);
                          formik.setFieldValue('category_code3', '-1');
                          const { data } = await SystemLib.api(
                            'category/list',
                            { code: e.target.value },
                            'post'
                          );
                          if (data.result) {
                            setCategories3(data.data ? data.data : []);
                          }
                        }}
                        error={Boolean(touched.category_code2 && errors.category_code2)}
                      >
                        <MenuItem value="-1">カテゴリー2選択...</MenuItem>
                        {categories2.map((v) => (
                          <MenuItem key={v.code} value={v.code}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Select
                        labelId="category_code3"
                        id="category_code3"
                        value={formik.values.category_code3}
                        {...getFieldProps('category_code3')}
                        onChange={async (e) => {
                          formik.handleChange(e);
                        }}
                        error={Boolean(touched.category_code3 && errors.category_code3)}
                      >
                        <MenuItem value="-1">カテゴリー3選択...</MenuItem>
                        {categories3.map((v) => (
                          <MenuItem key={v.code} value={v.code}>
                            {v.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      id="name"
                      label="商品名"
                      variant="outlined"
                      fullWidth
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    <TextField
                      id="price"
                      label="価格"
                      variant="outlined"
                      fullWidth
                      {...getFieldProps('price')}
                      error={Boolean(touched.price && errors.price)}
                      helperText={touched.price && errors.price}
                      onChange={formik.handleChange}
                      value={formik.values.price}
                    />
                    <FormControl>
                      <Select
                        labelId="commission_code"
                        id="commission_code"
                        value={formik.values.commission_code}
                        {...getFieldProps('commission_code')}
                        onChange={async (e) => {
                          formik.handleChange(e);
                          if (e.target.value !== '任意') {
                            formik.setFieldValue('commission_dt', null);
                            formik.setFieldValue('commission_current', 0);
                            formik.setFieldValue('commission_next', 0);
                          }
                          setTimeout(() => formik.setFieldTouched('commission_code', true));
                        }}
                        error={Boolean(touched.commission_code && errors.commission_code)}
                      >
                        <MenuItem value="-1">契約選択...</MenuItem>
                        {commissions.map((v) => (
                          <MenuItem key={v.code} value={v.code}>
                            {v.code}：{v.name}
                          </MenuItem>
                        ))}
                        <MenuItem value="任意">任意</MenuItem>
                      </Select>
                      {touched.commission_code && errors.commission_code && (
                        <FormHelperText sx={{ color: '#f00' }}>契約は必須です</FormHelperText>
                      )}
                    </FormControl>
                    {formik.values.commission_code === '任意' && (
                      <Stack direction="row" p={2} spacing={2} sx={{ backgroundColor: '#f0f8ff' }}>
                        <TextField
                          id="commission_current"
                          label="手数料率"
                          variant="outlined"
                          {...getFieldProps('commission_current')}
                          error={Boolean(touched.commission_current && errors.commission_current)}
                          helperText={touched.commission_current && errors.commission_current}
                          onChange={formik.handleChange}
                          value={formik.values.commission_current}
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                        />
                        <TextField
                          id="commission_next"
                          label="手数料率(適用日以降)"
                          variant="outlined"
                          {...getFieldProps('commission_next')}
                          error={Boolean(touched.commission_next && errors.commission_next)}
                          helperText={touched.commission_next && errors.commission_next}
                          onChange={formik.handleChange}
                          value={formik.values.commission_next}
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                        />
                        <DesktopDatePicker
                          id="commission_dt"
                          label="適用日"
                          inputFormat="yyyy-MM-dd"
                          {...getFieldProps('commission_dt')}
                          value={formik.values.commission_dt}
                          onChange={(v) => {
                            // alert(moment(v).toDate());
                            formik.setFieldValue('commission_dt', v);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={errors.commission_dt ? '適用日をご確認ください' : null}
                              fullWidth
                            />
                          )}
                        />
                      </Stack>
                    )}
                    {/* <TextField
                    id="size"
                    label="商品サイズ(カラム内で必要なコマ数)"
                    variant="outlined"
                    fullWidth
                    {...getFieldProps('size')}
                    error={Boolean(touched.size && errors.size)}
                    helperText={touched.size && errors.size}
                    onChange={formik.handleChange}
                    value={formik.values.size}
                  />
                  <TextField
                    id="caution_count"
                    label="補充検討個数"
                    variant="outlined"
                    fullWidth
                    {...getFieldProps('caution_count')}
                    error={Boolean(touched.caution_count && errors.caution_count)}
                    helperText={touched.caution_count && errors.caution_count}
                    onChange={formik.handleChange}
                    value={formik.values.caution_count}
                  />
                  <TextField
                    id="warning_count"
                    label="要補充個数"
                    variant="outlined"
                    fullWidth
                    {...getFieldProps('warning_count')}
                    error={Boolean(touched.warning_count && errors.warning_count)}
                    helperText={touched.warning_count && errors.warning_count}
                    onChange={formik.handleChange}
                    value={formik.values.warning_count}
                  /> 
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.update_sales === '1'}
                          onChange={(v) => {
                            formik.setFieldValue('update_sales', v.target.checked ? '1' : '0');
                          }}
                        />
                      }
                      label="過去の売上も更新する"
                    /> */}
                    <TextField
                      id="throughput"
                      label="製造日数"
                      variant="outlined"
                      fullWidth
                      {...getFieldProps('throughput')}
                      error={Boolean(touched.throughput && errors.throughput)}
                      helperText={touched.throughput && errors.throughput}
                      onChange={formik.handleChange}
                      value={formik.values.throughput}
                    />
                    <TextField
                      id="expiring"
                      label="消費期限(日数)"
                      variant="outlined"
                      fullWidth
                      {...getFieldProps('expiring')}
                      error={Boolean(touched.expiring && errors.expiring)}
                      helperText={touched.expiring && errors.expiring}
                      onChange={formik.handleChange}
                      value={formik.values.expiring}
                    />
                    <TextField
                      id="lot_order"
                      label="発注ロット"
                      variant="outlined"
                      fullWidth
                      {...getFieldProps('lot_order')}
                      error={Boolean(touched.lot_order && errors.lot_order)}
                      helperText={touched.lot_order && errors.lot_order}
                      onChange={formik.handleChange}
                      value={formik.values.lot_order}
                    />
                    <TextField
                      id="code_csv"
                      label="CSV内商品コード"
                      variant="outlined"
                      {...getFieldProps('code_csv')}
                      error={Boolean(touched.code_csv && errors.code_csv)}
                      helperText={touched.code_csv && errors.code_csv}
                      onChange={formik.handleChange}
                      value={formik.values.code_csv}
                    />
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={!isValid}
                    >
                      {`${targetId === 0 ? '登録' : '更新'}する`}
                    </LoadingButton>
                  </Stack>
                </Scrollbar>
              </Box>
            </Draggable>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
