import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function MoveModal({
  targetDeviceCode,
  targetColumnNumber,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);
  const valids = useRef({});
  const vals = useRef({});
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (targetDeviceCode) {
      _getList(targetDeviceCode, targetColumnNumber);
    }
    valids.current = {};
    vals.current = {};
  }, [targetDeviceCode, targetColumnNumber, updateDt]);

  const _getList = async (targetDeviceCode, targetColumnNumber) => {
    setIsLoading(true);
    const {
      data: { result, data, message }
    } = await SystemLib.api(
      `stock/column/sum`,
      {
        device_code: targetDeviceCode,
        column_number: targetColumnNumber
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      console.log(data);
      setList(data);
    } else {
      alert(message);
    }
  };

  const TABLE_HEAD = [
    { id: 'product_code', label: '商品コード', alignRight: false },
    { id: 'product_name', label: '商品名', alignRight: false },
    { id: 'quantity', label: '在荷数', alignRight: false },
    { id: '', label: '移動数' }
  ];

  const _valid = () => {
    let ch = true;
    Object.keys(valids.current).forEach((key) => {
      if (!valids.current[key]) ch = false;
    });
    setIsValid(ch);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="table,button"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Box>
                <Typography fontSize={18} align="center" color="#2e8b57">
                  {targetDeviceCode} - {targetColumnNumber}
                </Typography>
              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CollectListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {list.map((row, index) => (
                        <ItemRow
                          key={index}
                          row={row}
                          onChange={(v) => {
                            vals.current[row.product_code] = v;
                            _valid();
                          }}
                          onValid={(v) => {
                            valids.current[row.product_code] = v;
                            _valid();
                          }}
                        />
                      ))}
                      {isLoading === false && list.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                在荷がありません
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                読み込み中
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Box textAlign="center" sx={{ marginTop: 3 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                  onClick={() => {
                    console.log(vals.current);
                    setConfOk(() => async () => {
                      setIsLoading(true);
                      const {
                        data: { result, data }
                      } = await SystemLib.api(
                        `stock/column/move`,
                        {
                          device_code: targetDeviceCode,
                          column_number: targetColumnNumber,
                          moves: vals.current
                        },
                        'post'
                      );
                      setIsLoading(false);
                      if (result) {
                        console.log(data);
                        reload(targetDeviceCode, targetColumnNumber);
                        onClose();
                      } else {
                        alert('登録に失敗しました');
                      }
                    });
                    setConf(true);
                  }}
                >
                  移動する
                </LoadingButton>
              </Box>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          confOk();
          setConf(false);
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

function ItemRow({ row, onChange, onValid }) {
  const { product_code, product_name, quantity, move } = row;

  const yupNum = Yup.number()
    .typeError('数字のみ')
    .integer('整数のみ')
    .min(1, '1以上')
    .max(quantity, `${quantity}以下`)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    move: yupNum.required('必須')
  });

  const formik = useFormik({
    initialValues: {
      move
    },
    validationSchema: FormSchema,
    onSubmit: async () => {}
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  useEffect(() => {
    onValid(isValid);
  }, [isValid]);

  useEffect(() => {
    formik.setFieldValue('move', move);
  }, [row]);

  return (
    <TableRow hover key={product_code} tabIndex={-1}>
      <TableCell scope="row>">{product_code}</TableCell>
      <TableCell scope="row>">{product_name}</TableCell>
      <TableCell scope="row>">{quantity}</TableCell>
      <TableCell scope="row" padding="normal" align="center" width={120}>
        <TextField
          variant="outlined"
          size="small"
          sx={{ marginLeft: 1 }}
          {...getFieldProps('move')}
          error={Boolean(touched.move && errors.move)}
          helperText={touched.move && errors.move}
          onChange={(v) => {
            onChange(v.target.value);
            formik.handleChange(v);
          }}
          value={formik.values.move}
        />
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
