import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, View } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import backFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/stock/product';
import ConfDialog from '../../components/ConfDialog';
import SettingModal from './SettingModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'partner_code', label: 'パートナーコード', alignRight: false },
  { id: 'partner_name', label: 'パートナー名', alignRight: false },
  { id: 'code', label: '商品コード', alignRight: false },
  { id: 'name', label: '商品名', alignRight: false },
  { id: 'price', label: '価格', alignRight: true },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, partner_code = -1) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  if (partner_code !== -1) {
    array = filter(array, (_target) => _target.partner_code === partner_code);
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function DeviceProduct({
  open = false,
  deviceCode = 0,
  columnNumber = 0,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [modal, setModal] = useState(false);
  const [items, setItems] = useState([]);
  const [currentTargetCode, setCurrentTargetCode] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);

  const [partnerCode, setPartnerCode] = useState(-1);

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    getItems();
  }, [deviceCode, columnNumber, updateDt]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

  const filteredItems = applySortFilter(
    items,
    getComparator(order, orderBy),
    filterName,
    partnerCode
  );

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getItems = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('stock/products', {}, 'post');
    if (data.result) {
      setItems(data.data);
    }
    setIsLoading(false);
  };

  const setProduct = async (product_code) => {
    setIsLoading(true);
    const { data } = await SystemLib.api(
      'stock/setProduct',
      {
        device_code: deviceCode,
        column_number: columnNumber,
        product_code
      },
      'post'
    );
    if (data.result) {
      setCurrentTargetCode(0);
      reload(deviceCode, columnNumber);
      onClose();
    } else {
      alert(data.message);
    }
    setIsLoading(false);
  };

  const classes = useStyles();

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        background: '#fff',
        height: '100%',
        width: '100%',
        display: open ? 'block' : 'none'
      }}
    >
      <Page title="商品登録">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom display="block">
              {deviceCode}({columnNumber})：商品設定
            </Typography>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  setCurrentTargetCode(null);
                  setConf(true);
                }}
              >
                商品解除
              </Button>
              <Button
                variant="outlined"
                startIcon={<Icon icon={backFill} />}
                onClick={() => {
                  onClose();
                }}
              >
                戻る
              </Button>
            </Stack>
          </Stack>

          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {}}
              onChangePartnerCode={(code) => {
                setPartnerCode(code);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={items.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { id, code, name, price, partner_code, partner_name } = row;
                        const isItemSelected = selected.indexOf(id) !== -1;
                        return (
                          <TableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell component="th" scope="row" padding="normal">
                              {partner_code}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {partner_name}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {code}
                            </TableCell>
                            <TableCell component="th" scope="row" padding="normal">
                              {name}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="right"
                              sx={{ width: 150, paddingRight: 1 }}
                            >
                              {new Intl.NumberFormat('ja-JP').format(price)}
                            </TableCell>
                            <TableCell scope="row" padding="none" align="right" width={130}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                                justifyContent="center"
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="info"
                                  onClick={() => {
                                    setCurrentTargetCode(code);
                                    setModal(true);
                                  }}
                                >
                                  選択
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modal && (
          <SettingModal
            open={modal}
            updateDt={updateDt}
            targetDeviceCode={deviceCode}
            targetColumnNumber={columnNumber}
            targetProductCode={currentTargetCode}
            reload={reload}
            onClose={() => {
              setModal(false);
            }}
          />
        )}
        <ConfDialog
          open={conf}
          onOk={() => {
            setProduct(currentTargetCode);
            setConf(false);
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          商品を解除してもよろしいでしょうか？
          <br />
          <Typography sx={{ color: '#f00', marginTop: 1 }}>
            カラム内に商品が入っている場合、
            <br />
            「回収指示」が同時に発行されます
          </Typography>
        </ConfDialog>
      </Page>
    </div>
  );
}
