import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Select,
  MenuItem
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onChange = null
}) {
  const { SystemLib } = window;

  const [category1, setCategory1] = useState('-1');
  const [category2, setCategory2] = useState('-1');
  const [category3, setCategory3] = useState('-1');

  const [categories1, setCategories1] = useState([]);
  const [categories2, setCategories2] = useState([]);
  const [categories3, setCategories3] = useState([]);

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const { data } = await SystemLib.api('category/list', {}, 'post');
    if (data.result) {
      setCategories1(data.data ? data.data : []);
    }
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} 件 選択中
        </Typography>
      ) : (
        <Stack direction="row" spacing={2}>
          <Select
            labelId="select_label1"
            id="select1"
            value={category1}
            onChange={async (e) => {
              setCategory1(e.target.value);
              const { data } = await SystemLib.api(
                'category/list',
                { code: e.target.value },
                'post'
              );
              if (data.result) {
                setCategory2('-1');
                setCategory3('-1');
                setCategories2(data.data ? data.data : []);
                onChange({ category1: e.target.value, category2: '-1', category3: '-1' });
              }
            }}
          >
            <MenuItem value="-1">カテゴリー1選択...</MenuItem>
            {categories1.map((v) => (
              <MenuItem key={v.code} value={v.code}>
                {v.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="select_label2"
            id="select2"
            value={category2}
            onChange={async (e) => {
              setCategory2(e.target.value);
              const { data } = await SystemLib.api(
                'category/list',
                { code: e.target.value },
                'post'
              );
              if (data.result) {
                setCategory3('-1');
                setCategories3(data.data ? data.data : []);
                onChange({ category1, category2: e.target.value, category3: '-1' });
              }
            }}
          >
            <MenuItem value="-1">カテゴリー2選択...</MenuItem>
            {categories2.map((v) => (
              <MenuItem key={v.code} value={v.code}>
                {v.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="select_label3"
            id="select3"
            value={category3}
            onChange={async (e) => {
              setCategory3(e.target.value);
              const { data } = await SystemLib.api(
                'category/list',
                { code: e.target.value },
                'post'
              );
              if (data.result) {
                onChange({ category1, category2, category3: e.target.value });
              }
            }}
          >
            <MenuItem value="-1">カテゴリー3選択...</MenuItem>
            {categories3.map((v) => (
              <MenuItem key={v.code} value={v.code}>
                {v.name}
              </MenuItem>
            ))}
          </Select>

          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="商品コード / 商品名で検索..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="削除">
          <IconButton onClick={onDelete}>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}
