import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { SystemLib } = window;
  let systemName = '';
  switch (SystemLib.systemMode) {
    case 'POSS':
      systemName = 'POSS 連動システム';
      break;
    case 'EC':
      systemName = 'EC 連動システム';
      break;
    default:
      systemName = '売上/在庫/発注システム';
      break;
  }
  return (
    <RootStyle title="ログイン">
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '100%',
            height: 50,
            backgroundColor: '#0071bc',
            color: '#fff',
            fontSize: 20,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 20,
            paddingRight: 20
          }}
        >
          <div>{systemName} ログイン</div>
          <div>3G_EGSS</div>
        </div>
        <Container maxWidth="sm">
          <ContentStyle>
            <LoginForm />
          </ContentStyle>
        </Container>
      </div>
    </RootStyle>
  );
}
