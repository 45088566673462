import { Icon } from '@iconify/react';

import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import npmFill from '@iconify/icons-eva/npm-fill';
import checkmark from '@iconify/icons-eva/checkmark-square-outline';
import listFill from '@iconify/icons-eva/list-fill';
import cubeFill from '@iconify/icons-eva/cube-fill';
import cartFill from '@iconify/icons-eva/shopping-cart-fill';
import bagFill from '@iconify/icons-eva/shopping-bag-fill';
import carFill from '@iconify/icons-eva/car-fill';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import awardFill from '@iconify/icons-eva/award-fill';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import harddriveFill from '@iconify/icons-eva/hard-drive-fill';
import logoutFill from '@iconify/icons-eva/log-out-fill';
import folderOutline from '@iconify/icons-eva/folder-outline';
import pieChartOutline from '@iconify/icons-eva/pie-chart-outline';

import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = {
  master: [
    {
      title: 'ホーム',
      path: '/home/app',
      icon: getIcon(homeFill)
    },
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: '売上承認',
      path: '/approve/list',
      icon: getIcon(checkmark),
      badge: 'approve'
    },
    {
      title: 'デバイス：補充・回収指示の発報',
      path: '/stock/list',
      icon: getIcon(npmFill),
      badge: 'stock'
    },
    {
      title: '在荷商品一覧：回収・仕入指示の発報',
      path: '/products/list',
      icon: getIcon(cubeFill),
      badge: 'product'
    },
    {
      title: '仕入・荷受・保管の実施',
      path: '/layin/list',
      icon: getIcon(bagFill),
      badge: 'layin'
    },
    {
      title: '出荷・補充・回収の実施',
      path: '/ship/list',
      icon: getIcon(carFill),
      badge: 'ship'
    },
    {
      title: '移動・廃棄業務の実施',
      path: '/dispose/list',
      icon: getIcon(trashFill),
      badge: 'dispose'
    },
    {
      title: '保管庫の現状',
      path: '/storehouse/list',
      icon: getIcon(harddriveFill)
    },
    {
      title: 'hr'
    },
    {
      title: 'ユーザーマスター管理',
      path: '/user',
      icon: getIcon(peopleFill)
    },
    {
      title: 'パートナーマスター管理',
      path: '/partner/list',
      icon: getIcon(awardFill)
    },
    {
      title: 'デバイスマスター管理',
      path: '/device/list',
      icon: getIcon(keypadFill)
    },
    {
      title: '保管庫マスター管理',
      path: '/storage/list',
      icon: getIcon(harddriveFill)
    },
    {
      title: '手数料率管理',
      path: '/commission',
      icon: getIcon(pieChartOutline)
    },
    {
      title: 'カテゴリー管理',
      path: '/category',
      icon: getIcon(folderOutline)
    },
    {
      title: 'hr'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  admin: [
    {
      title: 'ホーム',
      path: '/home/app',
      icon: getIcon(homeFill)
    },
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: '売上承認',
      path: '/approve/list',
      icon: getIcon(checkmark),
      badge: 'approve'
    },
    {
      title: 'デバイス：補充・回収指示の発報',
      path: '/stock/list',
      icon: getIcon(npmFill),
      badge: 'stock'
    },
    {
      title: '在荷商品一覧：回収・仕入指示の発報',
      path: '/products/list',
      icon: getIcon(cubeFill),
      badge: 'product'
    },
    {
      title: '仕入・荷受・保管の実施',
      path: '/layin/list',
      icon: getIcon(bagFill),
      badge: 'layin'
    },
    {
      title: '出荷・補充・回収の実施',
      path: '/ship/list',
      icon: getIcon(carFill),
      badge: 'ship'
    },
    {
      title: '移動・廃棄業務の実施',
      path: '/dispose/list',
      icon: getIcon(trashFill),
      badge: 'dispose'
    },
    {
      title: '保管庫の現状',
      path: '/storehouse/list',
      icon: getIcon(harddriveFill)
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  staff: [
    {
      title: 'ホーム',
      path: '/home/app',
      icon: getIcon(homeFill)
    },
    {
      title: '仕入・荷受・保管の実施',
      path: '/layin/list',
      icon: getIcon(bagFill),
      badge: 'layin'
    },
    {
      title: '出荷・補充・回収の実施',
      path: '/ship/list',
      icon: getIcon(carFill),
      badge: 'ship'
    },
    {
      title: '移動・廃棄業務の実施',
      path: '/dispose/list',
      icon: getIcon(trashFill),
      badge: 'dispose'
    },
    {
      title: '保管庫の現状',
      path: '/storehouse/list',
      icon: getIcon(harddriveFill)
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  partner_admin: [
    {
      title: 'ホーム',
      path: '/home/app',
      icon: getIcon(homeFill)
    },
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: '受注・発送',
      path: '/order/list',
      icon: getIcon(carFill),
      badge: 'order'
    },
    {
      title: '売上承認',
      path: '/approve/list',
      icon: getIcon(checkmark),
      badge: 'approve'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  partner_user: [
    {
      title: 'ホーム',
      path: '/home/app',
      icon: getIcon(homeFill)
    },
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: '受注・発送',
      path: '/order/list',
      icon: getIcon(carFill),
      badge: 'order'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ]
};

export default sidebarConfig;
