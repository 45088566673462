import { filter, chunk } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, View, createElement } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import ArrowBack from '@iconify/icons-eva/arrow-ios-back-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Modal,
  Box,
  Backdrop,
  Paper,
  Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';

// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { ListHead, ListToolbar, MoreMenu } from '../../components/dispose';
import ConfDialog from '../../components/ConfDialog';
import StorageModal from './StorageModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'collect_dt', label: ['回収日時'], alignRight: false },
  { id: 'dispose_dt', label: ['廃棄日時'], alignRight: false },
  { id: 'kind', label: ['回収元'], alignRight: false },
  { id: 'product_code', label: ['商品コード'], alignRight: false },
  { id: 'lot_number', label: ['ロット番号'], alignRight: false },
  { id: 'product_name', label: ['商品名'], alignRight: false },
  { id: 'quantity', label: ['回収個数'], alignRight: false },
  { id: 'status', label: ['状況'], alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, status, kind) {
  if (query) {
    array = filter(
      array,
      (_target) =>
        _target.product_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _target.product_code.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  if (kind !== -1) {
    array = filter(array, (_target) => _target.kind === kind);
  }
  if (status !== -1) {
    array = filter(array, (_target) => _target.status === status);
  }
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Dispose() {
  const navigate = useNavigate();

  const { SystemLib } = window;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [list, setList] = useState([]);
  const [updateDt, setUpdateDt] = useState(moment().unix());

  const [currentProductCode, setCurrentProductCode] = useState('');
  const [currentProductName, setCurrentProductName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [conf, setConf] = useState(false);
  const [confAll, setConfAll] = useState(false);
  const [confOk, setConfOk] = useState(() => {});
  const [modalStorage, setModalStorage] = useState(false);

  const [currentId, setCurrentId] = useState(0);
  const [currentProduct, setCurrentProduct] = useState({ code: null, name: '' });
  const [currentMax, setCurrentMax] = useState(0);

  // フィルタ用
  const [status, setStatus] = useState(-1);
  const [kind, setKind] = useState(-1);

  useEffect(() => {
    getList();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredItems.map((n) => n.id_ds);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredItems = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName,
    status,
    kind
  );

  const isItemNotFound = filteredItems.length === 0;

  const useStyles = makeStyles({
    tableCell: {
      height: 10
    }
  });

  const getList = async () => {
    setIsLoading(true);
    const { data } = await SystemLib.api('dispose/list', {}, 'post');
    if (data.result) {
      setList(data.data);
    }
    setIsLoading(false);
  };

  const getItem = async (id_ds) => {
    const { data } = await SystemLib.api(
      `dispose/item`,
      {
        id_ds
      },
      'post'
    );
    if (data.result) {
      let flag = false;
      if (list) {
        const arr = list.slice();
        arr.forEach((v, i) => {
          if (v.id_ds === id_ds) {
            arr[i] = data.data;
            setList(arr);
            flag = true;
          }
        });
      }
      if (!flag) {
        // setOrderBy(null);
        const arr = list.slice();
        arr.unshift(data.data);
        setList(arr);
      }
    }
  };

  const disposeItems = async (id_dss) => {
    const ret = await SystemLib.api(
      `dispose/dispose`,
      {
        id_dss
      },
      'post'
    );
    getList();
  };

  const reload = async (product_code = null) => {
    if (product_code !== null) {
      getItem(product_code);
    } else {
      getList();
    }
  };

  const classes = useStyles();

  return (
    <>
      <Page title="移動・廃棄業務の実施">
        <Container sx={{ position: 'relative' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
            <Typography variant="h4" gutterBottom>
              移動・廃棄業務の実施
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              variant="outlined"
              startIcon={<Icon icon={ArrowBack} />}
              onClick={() => {
                navigate(-1);
              }}
            >
              戻る
            </Button>
          </Stack>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              onDelete={() => {}}
              onDispose={() => {
                setConfAll(true);
              }}
              onChangeKind={(kind) => {
                setKind(kind);
                setPage(0);
              }}
              onChangeStatus={(status) => {
                setStatus(status);
                setPage(0);
              }}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={filteredItems.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredItems
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          id_ds,
                          collect_dt,
                          dispose_dt,
                          kind,
                          product_code,
                          lot_number,
                          quantity,
                          status,
                          product_name
                        } = row;
                        const isItemSelected = selected.indexOf(id_ds) !== -1;
                        // const numberWithComma = new Intl.NumberFormat();
                        // const priceFormat = numberWithComma.format(price);
                        return (
                          <TableRow
                            hover
                            key={id_ds}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                            sx={{ backgroundColor: status === '廃棄済' ? '#eee' : null }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, id_ds)}
                              />
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {moment(collect_dt).format('YYYY-MM-DD HH:mm')}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {dispose_dt ? moment(dispose_dt).format('YYYY-MM-DD HH:mm') : null}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {kind}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {product_code}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {lot_number}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {product_name}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {quantity}
                            </TableCell>
                            <TableCell scope="row" padding="normal">
                              {status}
                            </TableCell>
                            <TableCell scope="row" align="right" width={200} padding="none">
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                              >
                                {status === '回収済' && (
                                  <>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="success"
                                      onClick={() => {
                                        setUpdateDt(moment().unix());
                                        setCurrentId(id_ds);
                                        // setCurrentProduct(product_code);
                                        setCurrentProductCode(product_code);
                                        setCurrentProductName(product_name);
                                        setCurrentMax(quantity);
                                        setModalStorage(true);
                                      }}
                                      disabled={selected.length > 0}
                                    >
                                      保管庫移動
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="error"
                                      onClick={() => {
                                        setConfOk(() => async () => {
                                          setIsLoading(true);
                                          const {
                                            data: { result, data }
                                          } = await SystemLib.api(
                                            `dispose/dispose`,
                                            {
                                              id_dss: [id_ds]
                                            },
                                            'post'
                                          );
                                          setIsLoading(false);
                                          if (result) {
                                            reload(id_ds);
                                          } else {
                                            alert('失敗しました');
                                          }
                                        });
                                        setConf(true);
                                      }}
                                      disabled={selected.length > 0}
                                    >
                                      廃棄完了
                                    </Button>
                                  </>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isItemNotFound && !isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {isItemNotFound && isLoading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              読み込み中
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 50, 100]}
              component="div"
              count={filteredItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="表示件数"
            />
          </Card>
        </Container>
        {modalStorage && (
          <StorageModal
            targetId={currentId}
            targetProductCode={currentProductCode}
            targetProductName={currentProductName}
            targetMax={currentMax}
            open={modalStorage}
            onClose={() => {
              setModalStorage(false);
            }}
            updateDt={updateDt}
            reload={(id) => {
              reload(id);
            }}
          />
        )}
        <ConfDialog
          open={conf}
          onOk={() => {
            confOk();
            setConf(false);
          }}
          onCancel={() => {
            setConf(false);
          }}
          onClose={() => {
            setConf(false);
          }}
        >
          実行してもよろしいでしょうか？
        </ConfDialog>
        <ConfDialog
          open={confAll}
          onOk={() => {
            disposeItems(selected);
            setSelected([]);
            setConfAll(false);
          }}
          onCancel={() => {
            setConfAll(false);
          }}
          onClose={() => {
            setConfAll(false);
          }}
        >
          実行してもよろしいでしょうか？ ({selected.length}件)
        </ConfDialog>
      </Page>
    </>
  );
}
