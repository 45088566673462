import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function CancelModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      formik.setValues({
        reason_cancel: ''
      });
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const FormSchema = Yup.object().shape({
    reason_cancel: Yup.string().required('キャンセル理由は必須です')
  });

  const formik = useFormik({
    initialValues: {
      reason_cancel: ''
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        'layin/cancel',
        {
          order_id: targetId,
          reason_cancel: formik.values.reason_cancel
        },
        'post'
      );
      if (data.result) {
        reload(targetId);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
              <Stack direction="column" spacing={2} mt={0} mr={2} width="100%" mb={2}>
                <TextField
                  id="reason_cancel"
                  label="キャンセル理由"
                  variant="outlined"
                  {...getFieldProps('reason_cancel')}
                  error={Boolean(touched.reason_cancel && errors.reason_cancel)}
                  helperText={touched.reason_cancel && errors.reason_cancel}
                  onChange={formik.handleChange}
                  value={formik.values.reason_cancel}
                  multiline
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="error"
                loading={isSubmitting}
                disabled={!isValid}
              >
                キャンセルする
              </LoadingButton>
            </Box>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
