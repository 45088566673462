import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
  Select,
  MenuItem
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function SaleCsvModal({ open, onClose, reload, keycode, data }) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState(data);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {}, []);

  const TABLE_HEAD = [
    { id: 'sale_dt', label: '販売日時', alignRight: false },
    { id: 'device_code', label: 'デバイスコード', alignRight: false },
    { id: 'device_name', label: 'デバイス名', alignRight: false },
    { id: 'column_number', label: 'カラム番号', alignRight: false },
    { id: 'product_code', label: '商品コード', alignRight: false },
    { id: 'product_name', label: '商品名', alignRight: false },
    { id: 'state', label: '検証', alignRight: false }
  ];

  let err = false;
  list.forEach((v) => {
    if (v.status !== 'success') {
      err = true;
    }
  });

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="table,button"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CollectListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {list.map((row, index) => (
                        <ItemRow key={index} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <Box textAlign="center" sx={{ marginTop: 3 }}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={err}
                  onClick={() => {
                    setConfOk(() => async () => {
                      setIsLoading(true);
                      setIsSubmitting(true);
                      const {
                        data: { result, data, message }
                      } = await SystemLib.api(
                        `sale/csv_apply`,
                        {
                          key: keycode
                        },
                        'post'
                      );
                      setIsLoading(false);
                      setIsSubmitting(false);
                      if (result) {
                        reload();
                        onClose();
                      } else {
                        alert(message);
                      }
                    });
                    setConf(true);
                  }}
                >
                  登録する
                </LoadingButton>
              </Box>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          confOk();
          setConf(false);
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

function ItemRow({ row }) {
  const {
    id,
    sale_dt,
    device_code,
    device_name,
    column_number,
    product_code,
    product_name,
    status
  } = row;
  let status_str = '';
  let err_flag = false;
  switch (status) {
    case 'success':
      status_str = '適正';
      break;
    case 'e01':
      status_str = 'コード不一致';
      err_flag = true;
      break;
    case 'e02':
      status_str = 'カラム不一致';
      err_flag = true;
      break;
    default:
      status_str = 'エラー';
      err_flag = true;
      break;
  }
  return (
    <TableRow hover key={id} tabIndex={-1}>
      <TableCell scope="row>">{sale_dt}</TableCell>
      <TableCell scope="row>">{device_code}</TableCell>
      <TableCell scope="row>">{device_name}</TableCell>
      <TableCell scope="row>">{column_number}</TableCell>
      <TableCell scope="row>">{product_code}</TableCell>
      <TableCell scope="row>">{product_name}</TableCell>
      <TableCell scope="row>" sx={{ backgroundColor: err_flag ? '#ffe4e1' : null }}>
        {status_str}
      </TableCell>
    </TableRow>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    overflow: 'scroll'
  }
};
