import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// components
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

const RootStyle = styled(AppBar)(({ theme, role }) => {
  let color = '#2e8b57';
  switch (role) {
    case 'master':
      color = '#8d93c8';
      break;
    case 'admin':
      color = '#8d93c8';
      break;
    case 'staff':
      color = '#8d93c8';
      break;
    case 'partner_admin':
      color = '#8d93c8';
      break;
    case 'partner_user':
      color = '#8d93c8';
      break;
    default:
  }
  return {
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
      // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    },
    borderBottomWidth: '5px',
    borderBottomColor: alpha(color, 0.72),
    borderBottomStyle: 'solid'
  };
});

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  backgroundColor: 'rgba(250,237,0,0.3)',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ theme, onOpenSidebar }) {
  const { SystemLib } = window;

  let roleStr = '';
  switch (SystemLib?.userInfo?.role) {
    case 'master':
      roleStr = 'Master';
      break;
    case 'admin':
      roleStr = 'Administrator';
      break;
    case 'staff':
      roleStr = 'Staff';
      break;
    case 'partner_admin':
      roleStr = 'Partner Administrator';
      break;
    case 'partner_user':
      roleStr = 'Partner User';
      break;
    default:
      roleStr = 'user';
  }

  return (
    <RootStyle role={SystemLib.userInfo.role}>
      <ToolbarStyle>
        {/* <MHidden width="lgUp"> */}
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
          <Icon icon={menu2Fill} />
        </IconButton>
        {/* </MHidden> */}
        {/* <Searchbar /> */}
        <Typography color="#0b308e" variant="h4">
          受発注／在庫システム（R2事業再構築） for {roleStr}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover />
          <NotificationsPopover /> */}
          {/* <AccountPopover /> */}
          {SystemLib.userInfo.partner_name && (
            <Typography color="#0b308e">{SystemLib.userInfo.partner_name}</Typography>
          )}
          {!SystemLib.userInfo.partner_name && <Typography color="#0b308e">3Gサポート</Typography>}
          <Typography color="#0b308e">／</Typography>
          <Typography color="#0b308e">{SystemLib.userInfo.name}</Typography>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
