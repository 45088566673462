import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function StorageModal({ targetId = 0, open, onClose, updateDt, reload }) {
  const { SystemLib } = window;
  const navigate = useNavigate();

  useEffect(() => {}, []);

  useEffect(() => {
    if (targetId > 0) {
      getItem(targetId);
    } else {
      formik.resetForm();
    }
  }, [targetId, updateDt]);

  const getItem = async (id) => {
    const {
      data: { result, data }
    } = await SystemLib.api(`storage/${id}`, {}, 'get');
    if (result) {
      formik.setValues({
        code: data.code,
        name: data.name,
        amount: data.amount
      });
    }
  };

  const yupNum = Yup.number()
    .typeError('数字を入力してください')
    .integer('整数を入力してください')
    .min(0, '0以上の数字を入れてください')
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

  const FormSchema = Yup.object().shape({
    name: Yup.string().required('端末名は必須です'),
    amount: yupNum.required('最大貯蔵量は必須です')
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      amount: ''
    },
    validationSchema: FormSchema,
    onSubmit: async () => {
      const { data } = await SystemLib.api(
        `storage${targetId === 0 ? '' : `/${targetId}`}`,
        {
          code: formik.values.code,
          name: formik.values.name,
          amount: formik.values.amount
        },
        targetId === 0 ? 'post' : 'put'
      );
      if (data.result) {
        reload(data.data.id);
        onClose();
      } else {
        alert('登録に失敗しました');
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <FormikProvider value={formik}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Draggable
              cancel="input"
              defaultPosition={{ x: 0, y: 0 }}
              positionOffset={{ x: '-50%', y: '-50%' }}
            >
              <Box sx={styles.box} spacing={2} maxHeight="80%" overflow="scroll">
                <Stack direction="column" spacing={2} mt={0} mr={2} width="100%" mb={2}>
                  {targetId > 0 && (
                    <TextField
                      id="code"
                      label="保管庫コード"
                      variant="outlined"
                      value={formik.values.code}
                      disabled
                    />
                  )}
                  <TextField
                    id="name"
                    label="保管庫名"
                    variant="outlined"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  <TextField
                    id="amount"
                    label="最大貯蔵量"
                    variant="outlined"
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                  />
                </Stack>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  {`${targetId === 0 ? '登録' : '更新'}する`}
                </LoadingButton>
              </Box>
            </Draggable>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </Modal>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
