import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Select,
  MenuItem
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onChangeDevice: PropTypes.func,
  onChangeStorage: PropTypes.func
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onChange,
  onChangeDevice,
  onChangeStorage
}) {
  const { SystemLib } = window;
  const [status, setStatus] = useState(-1);
  const statuses = ['回収指示', '出荷指示', '出荷済/補充'];

  const [device, setDevice] = useState(-1);
  const [storage, setStorage] = useState(-1);
  const [devices, setDevices] = useState([]);
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    const { data: dataD } = await SystemLib.api('ship/devices', {}, 'post');
    if (dataD.result) {
      setDevices(dataD.data);
    }
    const { data: dataS } = await SystemLib.api('ship/storages', {}, 'post');
    if (dataS.result) {
      setStorages(dataS.data);
    }
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} 件 選択中
        </Typography>
      ) : (
        <Stack direction="row" spacing={2}>
          <Select
            labelId="select_label_devices"
            id="select_devices"
            value={device}
            onChange={(e) => {
              setDevice(e.target.value);
              onChangeDevice(e.target.value);
            }}
          >
            <MenuItem value={-1}>デバイス 選択...</MenuItem>
            {devices.map((v) => (
              <MenuItem key={v.with_device.code} value={v.with_device.code}>
                {v.with_device.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="select_label_storages"
            id="select_storages"
            value={storage}
            onChange={(e) => {
              setStorage(e.target.value);
              onChangeStorage(e.target.value);
            }}
          >
            <MenuItem value={-1}>保管庫 選択...</MenuItem>
            {storages.map((v) => (
              <MenuItem key={v.with_storage.code} value={v.with_storage.code}>
                {v.with_storage.name}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="select_label"
            id="select"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              onChange(e.target.value);
            }}
          >
            <MenuItem value={-1}>状態 選択...</MenuItem>
            {statuses.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="商品名 検索..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="削除">
          <IconButton onClick={onDelete}>
            <Icon icon={trash2Fill} />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}
