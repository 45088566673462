import { Icon } from '@iconify/react';

import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import npmFill from '@iconify/icons-eva/npm-fill';
import checkmark from '@iconify/icons-eva/checkmark-square-outline';
import listFill from '@iconify/icons-eva/list-fill';
import cubeFill from '@iconify/icons-eva/cube-fill';
import cartFill from '@iconify/icons-eva/shopping-cart-fill';
import bagFill from '@iconify/icons-eva/shopping-bag-fill';
import carFill from '@iconify/icons-eva/car-fill';
import trashFill from '@iconify/icons-eva/trash-2-fill';
import awardFill from '@iconify/icons-eva/award-fill';
import keypadFill from '@iconify/icons-eva/keypad-fill';
import harddriveFill from '@iconify/icons-eva/hard-drive-fill';
import logoutFill from '@iconify/icons-eva/log-out-fill';
import folderOutline from '@iconify/icons-eva/folder-outline';
import pieChartOutline from '@iconify/icons-eva/pie-chart-outline';

import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig2 = {
  master: [
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: 'hr'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  admin: [
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: 'hr'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  staff: [
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  partner_admin: [
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: 'hr'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ],
  partner_user: [
    {
      title: '売上一覧',
      path: '/sale/list',
      icon: getIcon(cartFill)
    },
    {
      title: 'hr'
    },
    {
      title: 'ログアウト',
      path: '/login',
      icon: getIcon(logoutFill)
    }
  ]
};

export default sidebarConfig2;
