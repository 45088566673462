import { useState } from 'react';
import { Navigate, useRoutes, BrowserRouter, Routes, Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import DashboardApp from './pages/DashboardApp';
import User from './pages/user/User';
import Partner from './pages/partner/Partner';
import Device from './pages/device/Device';
import Stock from './pages/stock/Stock';
import Ship from './pages/ship/Ship';
import Products from './pages/products/Products';
import Dispose from './pages/dispose/Dispose';
import Layin from './pages/layin/Layin';
import Storage from './pages/storage/Storage';
import Sale from './pages/sale/Sale';
import PartnerSale from './pages/sale/partner/Sale';
import Approve from './pages/approve/Approve';
import PartnerApprove from './pages/approve/partner/Approve';
import Order from './pages/order/Order';
import Category from './pages/category/Category';
import Storehouse from './pages/storehouse/Storehouse';
import Commission from './pages/commission/Commission';

import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  const { SystemLib } = window;
  const { userInfo } = SystemLib;
  const [isAuth, setIsAuth] = useState(SystemLib.isAuth);
  window.setIsAuth = setIsAuth;

  const routes_pe = [
    {
      path: '/home',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/home/app" replace /> },
        { path: 'app', element: <DashboardApp /> }
      ]
    },
    {
      path: '/user',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/user/list" replace /> },
        { path: 'list', element: <User /> }
      ]
    },
    {
      path: '/partner',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/partner/list" replace /> },
        { path: 'list', element: <Partner /> }
      ]
    },
    {
      path: '/device',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/device/list" replace /> },
        { path: 'list', element: <Device /> }
      ]
    },
    {
      path: '/stock',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/stock/list" replace /> },
        { path: 'list', element: <Stock /> }
      ]
    },
    {
      path: '/ship',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/ship/list" replace /> },
        { path: 'list', element: <Ship /> }
      ]
    },
    {
      path: '/products',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/products/list" replace /> },
        { path: 'list', element: <Products /> }
      ]
    },
    {
      path: '/dispose',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dispose/list" replace /> },
        { path: 'list', element: <Dispose /> }
      ]
    },
    {
      path: '/layin',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/layin/list" replace /> },
        { path: 'list', element: <Layin /> }
      ]
    },
    {
      path: '/storage',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/storage/list" replace /> },
        { path: 'list', element: <Storage /> }
      ]
    },
    {
      path: '/sale',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/sale/list" replace /> },
        { path: 'list', element: <Sale /> },
        { path: 'list/:p/:ym', element: <Sale /> }
      ]
    },
    {
      path: '/approve',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/approve/list" replace /> },
        { path: 'list', element: <Approve /> }
      ]
    },
    {
      path: '/category',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/category/list" replace /> },
        { path: 'list', element: <Category /> },
        { path: 'list/:pid', element: <Category /> }
      ]
    },
    {
      path: '/storehouse',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/storehouse/list" replace /> },
        { path: 'list', element: <Storehouse /> }
      ]
    },
    {
      path: '/commission',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/commission/list" replace /> },
        { path: 'list', element: <Commission /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/home" replace /> }
  ];

  const routes_pn = [
    {
      path: '/home',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/home/app" replace /> },
        { path: 'app', element: <DashboardApp /> }
      ]
    },
    {
      path: '/sale',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/sale/list" replace /> },
        { path: 'list', element: <PartnerSale /> }
      ]
    },
    {
      path: '/approve',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/approve/list" replace /> },
        { path: 'list', element: <PartnerApprove /> }
      ]
    },
    {
      path: '/order',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/order/list" replace /> },
        { path: 'list', element: <Order /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/home" replace /> }
  ];

  const routes_poss_ec_pe = [
    {
      path: '/sale',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/sale/list" replace /> },
        { path: 'list', element: <Sale /> },
        { path: 'list/:p/:ym', element: <Sale /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/sale/list" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/sale/list" replace /> }
  ];

  const routes_poss_ec_pn = [
    {
      path: '/sale',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/sale/list" replace /> },
        { path: 'list', element: <PartnerSale /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/sale/list" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/sale/list" replace /> }
  ];

  let route =
    userInfo.role === 'master' || userInfo.role === 'admin' || userInfo.role === 'staff'
      ? routes_pe
      : routes_pn;
  if (SystemLib.systemMode === 'POSS' || SystemLib.systemMode === 'EC') {
    route =
      userInfo.role === 'master' || userInfo.role === 'admin' || userInfo.role === 'staff'
        ? routes_poss_ec_pe
        : routes_poss_ec_pn;
  }
  return useRoutes(
    isAuth
      ? route
      : [
          {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
              { path: 'login', element: <Login /> },
              { path: '404', element: <NotFound /> },
              { path: '/', element: <Navigate to="/login" /> },
              { path: '*', element: <Navigate to="/login" /> }
            ]
          },
          { path: '*', element: <Navigate to="/login" replace /> }
        ]
  );
}
