import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Stack,
  Select,
  MenuItem,
  Button
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDelete: PropTypes.func
};

export default function ListToolbar({
  numSelected,
  filterName,
  onFilterName,
  onDelete,
  onChangeStatus,
  onChangeKind,
  onDispose = null
}) {
  const [status, setStatus] = useState(-1);
  const statuses = ['回収済', '廃棄済'];
  const [kind, setKind] = useState(-1);
  const kinds = ['デバイス', '保管庫'];

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} 件 選択中
        </Typography>
      ) : (
        <Stack direction="row" spacing={2}>
          <Select
            labelId="select_label_kind"
            id="select_kind"
            value={kind}
            onChange={(e) => {
              setKind(e.target.value);
              onChangeKind(e.target.value);
            }}
          >
            <MenuItem value={-1}>回収元 選択...</MenuItem>
            {kinds.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
          <Select
            labelId="select_label"
            id="select"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              onChangeStatus(e.target.value);
            }}
          >
            <MenuItem value={-1}>状況 選択...</MenuItem>
            {statuses.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="商品 検索..."
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
        </Stack>
      )}

      {numSelected > 0 ? (
        <Tooltip title="一括廃棄">
          <Button
            size="small"
            type="button"
            variant="contained"
            disableElevation
            onClick={() => {
              onDispose();
            }}
          >
            廃棄する
          </Button>
        </Tooltip>
      ) : null}
    </RootStyle>
  );
}
