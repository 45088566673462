import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Container,
  Typography,
  Divider,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Paper
} from '@mui/material';
import Draggable, { DraggableCore } from 'react-draggable';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import jaLocale from 'date-fns/locale/ja';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import moment from 'moment';

import { CollectListHead } from '../../components/stock';
import ConfDialog from '../../components/ConfDialog';

// components
import Scrollbar from '../../components/Scrollbar';
//

// ----------------------------------------------------------------------

export default function CollectModal({
  targetDeviceCode,
  targetColumnNumber,
  open,
  onClose,
  updateDt,
  reload
}) {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [conf, setConf] = useState(false);
  const [confOk, setConfOk] = useState(() => () => {});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {}, []);

  useEffect(() => {
    setList([]);
    if (targetDeviceCode) {
      _getList(targetDeviceCode, targetColumnNumber);
    }
  }, [targetDeviceCode, targetColumnNumber, updateDt]);

  const _getList = async (targetDeviceCode, targetColumnNumber) => {
    setIsLoading(true);
    const {
      data: { result, data }
    } = await SystemLib.api(
      `stock/column`,
      {
        device_code: targetDeviceCode,
        column_number: targetColumnNumber
      },
      'post'
    );
    setIsLoading(false);
    if (result) {
      setList(data);
    }
  };

  const _setState = async (id, status) => {
    const {
      data: { result }
    } = await SystemLib.api(
      `stock/column/status`,
      {
        id,
        status
      },
      'post'
    );
    if (result) {
      _getList(targetDeviceCode, targetColumnNumber);
    }
  };

  const TABLE_HEAD = [
    { id: 'product_code', label: '商品コード', alignRight: false },
    { id: 'lot_number', label: 'ロット番号', alignRight: false },
    { id: 'product_name', label: '商品名', alignRight: false },
    { id: 'quantity', label: '数量', alignRight: true },
    { id: 'expiry_dt', label: '消費期限', alignRight: false },
    { id: 'expiry_days', label: '残日数', alignRight: true },
    { id: 'exp_status', label: '状態', alignRight: true },
    { id: '' }
  ];

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          onClose();
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={jaLocale}>
          <Draggable
            cancel="table,button"
            defaultPosition={{ x: 0, y: 0 }}
            positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Box sx={styles.box} spacing={2} maxHeight="80%">
              <Box>
                <Typography fontSize={18} align="center" color="#2e8b57">
                  {targetDeviceCode} - {targetColumnNumber}
                </Typography>
              </Box>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <CollectListHead headLabel={TABLE_HEAD} />
                    <TableBody>
                      {list.map((row) => {
                        const {
                          id,
                          column_number,
                          product_code,
                          product_name,
                          lot_number,
                          expiry_dt,
                          expiry_days,
                          quantity,
                          exp_status,
                          status
                        } = row;
                        let exp_color = null;
                        let exp_label = '適正';
                        switch (exp_status) {
                          case 'expired':
                            exp_color = '#fad9d2';
                            exp_label = '期限切れ';
                            break;
                          case 'warning':
                            exp_color = '#fad9d2';
                            exp_label = '回収必須';
                            break;
                          case 'caution':
                            exp_color = '#fafad2';
                            exp_label = '回収注意';
                            break;
                          default:
                            break;
                        }
                        return (
                          <TableRow hover key={id} tabIndex={-1}>
                            <TableCell scope="row>">{product_code}</TableCell>
                            <TableCell scope="row">{lot_number}</TableCell>
                            <TableCell scope="row">{product_name}</TableCell>
                            <TableCell scope="row" align="center">
                              {quantity}
                            </TableCell>
                            <TableCell scope="row">{expiry_dt}</TableCell>
                            <TableCell scope="row" align="center">
                              {expiry_days}
                            </TableCell>
                            <TableCell
                              scope="row"
                              padding="none"
                              align="center"
                              sx={{
                                backgroundColor: exp_color
                              }}
                            >
                              {exp_label}
                            </TableCell>
                            <TableCell scope="row" padding="none" align="right" width={80}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                paddingLeft={1}
                              >
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    setConfOk(() => async () => {
                                      await _setState(id, 'pre_collect');
                                      reload(targetDeviceCode, targetColumnNumber);
                                    });
                                    setConf(true);
                                  }}
                                  disabled={status === 'pre_collect'}
                                >
                                  {status === 'pre_collect' ? '指示済' : '回収指示'}
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {isLoading === false && list.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                在荷がありません
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                      {isLoading && (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <Paper>
                              <Typography gutterBottom align="center" variant="subtitle1">
                                読み込み中
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Box>
          </Draggable>
        </LocalizationProvider>
      </Modal>
      <ConfDialog
        open={conf}
        onOk={() => {
          confOk();
          setConf(false);
        }}
        onCancel={() => {
          setConf(false);
        }}
        onClose={() => {
          setConf(false);
        }}
      >
        実行してもよろしいでしょうか？
      </ConfDialog>
    </>
  );
}

const styles = {
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '1px solid',
    borderColor: 'rgb(96,169,91)',
    borderRadius: 1,
    boxShadow: 24,
    p: 4
  }
};
