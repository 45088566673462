import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { Box, Grid, Container, Typography, Button, ButtonBase, Badge } from '@mui/material';

// ----------------------------------------------------------------------

// components
import Page from '../components/Page';
import sidebarConfig from '../layouts/dashboard/SidebarConfig';

const images = [
  {
    url: '/static/buttons/btn_2.png',
    title: '売上承認',
    width: '160px',
    navi: '/approve/list'
  },
  {
    url: '/static/buttons/btn_3.png',
    title: 'デバイス：補充・回収指示の発報',
    width: '160px',
    navi: '/stock/list'
  },
  {
    url: '/static/buttons/btn_9.png',
    title: 'ユーザーマスター管理',
    width: '160px',
    navi: '/user/list'
  },
  {
    url: '/static/buttons/btn_12.png',
    title: '保管庫マスター管理',
    width: '160px',
    navi: '/storage/list'
  },
  {
    url: '/static/buttons/btn_5.png',
    title: '仕入・荷受・保管の実施',
    width: '160px',
    navi: '/layin/list'
  },
  {
    url: '/static/buttons/btn_6.png',
    title: '出荷・補充・回収の実施',
    width: '160px',
    navi: '/ship/list'
  },
  {
    url: '/static/buttons/btn_11.png',
    title: 'デバイスマスター管理',
    width: '160px',
    navi: '/device/list'
  },
  {
    url: '/static/buttons/btn_13.png',
    title: '手数料率管理',
    width: '160px',
    navi: '/commission/list'
  },
  {
    url: '/static/buttons/btn_7.png',
    title: '移動・廃棄業務の実施',
    width: '160px',
    navi: '/dispose/list'
  },
  {
    url: '/static/buttons/btn_8.png',
    title: '保管庫の現状',
    width: '160px',
    navi: '/storehouse/list'
  },
  {
    url: '/static/buttons/btn_10.png',
    title: 'パートナーマスター管理',
    width: '160px',
    navi: '/partner/list'
  },
  {
    url: '/static/buttons/btn_14.png',
    title: 'カテゴリー管理',
    width: '160px',
    navi: '/category/list'
  }
];

const ImageButton = (props) => {
  const { alt, src, onClick, style } = props;
  return (
    <Button
      {...style}
      onClick={() => {
        onClick();
      }}
      disableRipple
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: 'transparent'
        },
        '& img': {
          maxWidth: '100%',
          height: 'auto',
          transition: 'filter 0.3s ease'
        },
        '&:hover img': {
          filter: 'brightness(120%)'
        }
      }}
    >
      <img alt={alt} src={src} style={{ maxWidth: '100%', height: 'auto' }} />
    </Button>
  );
};

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { SystemLib } = window;
  const navigate = useNavigate();
  const { userInfo } = SystemLib;
  const menus = sidebarConfig[userInfo.role];
  const [badges, setBadges] = useState({});

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const tmp = { ...badges };
    const keys = Object.keys(menus);
    for (let i = 0; i < keys.length; i += 1) {
      if (menus[keys[i]].badge) {
        // eslint-disable-next-line no-await-in-loop
        const { data } = await SystemLib.api(`${menus[keys[i]].badge}/badge`, {}, 'post');
        if (data.result) {
          tmp[menus[keys[i]].badge] = data.data.badge;
        } else {
          tmp[menus[keys[i]].badge] = 0;
        }
      }
    }
    setBadges(tmp);
  };

  return (
    <Page title="ホーム">
      <Container maxWidth="xl" style={{}}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 800,
              height: 280,
              backgroundImage: `url('/static/buttons/bg.png')`,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            <ImageButton
              alt="売上一覧"
              src="/static/buttons/btn_1.png"
              onClick={() => {
                navigate(`/sale/list/`);
              }}
            />
            <ImageButton
              alt="在荷商品一覧：回収・仕入指示の発報"
              src="/static/buttons/btn_4.png"
              onClick={() => {
                navigate(`/products/list`);
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              width: 800,
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 30
            }}
          >
            {images.map((image, index) => (
              <ImageButton
                key={index}
                alt={image.title}
                src={image.url}
                onClick={() => {
                  navigate(image.navi);
                }}
                style={{
                  flex: '0 0 25%'
                }}
              />
            ))}
          </div>
        </div>
        <Box sx={{ mt: 2, color: '#888' }}>
          <Typography align="right">R2 事業再構築</Typography>
        </Box>
      </Container>
    </Page>
  );
}
